<template>
    <div class="privacyPolicy">
        <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>
        <main>
            <h2>学前必读</h2>
            <div ref="content" class="w-e-panel-content-table policy-content-text" data-slate-editor
                v-html="data.content">
            </div>
        </main>
        <footerBottom></footerBottom>
    </div>
</template>
 
<script>
import topNav from "@/components/nav/nav.vue"
import { httpNPGet, httpGet } from "@/utils/httpRequest";
import footerBottom from "@/components/footer/footer.vue"
import '@wangeditor/editor/dist/css/style.css'
export default {
    name: "privacyPolicy",
    data() {
        return {
            pageIndex: 0,
            data: {

            }
        }
    },
    components: {
        topNav,
        footerBottom
    },
    methods: {
        getData() {
            httpNPGet("/news-info/must", (res) => {
                // console.log(res.data)
                if (res.data != null) {
                    this.data = res.data
                } else {
                    this.data = {}
                }
                // this.$nextTick(() => {
                //     let table = this.$refs.content.querySelector("table");
                //     if (table) {
                //         table.style.width = "100%";
                //     }
                // });
            }, (err) => {
                cosnole.log(err)
            })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
 
<style scoped lang='scss'>
main {
    width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 50px;
    margin-bottom: 30px;
    margin-top: 30px;
    background-color: #fff;

    h2 {
        text-align: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;
        padding-bottom: 30px;
    }
}
</style>